<template>
  <path
    class="clip"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.8433 1C11.7387 1 10.8433 1.89543 10.8433 3H7.84328V4C7.84328 4.55228 8.29099 5 8.84328 5H16.8433C17.3956 5 17.8433 4.55228 17.8433 4V3H14.8433C14.8433 1.89543 13.9478 1 12.8433 1Z"
    fill="currentColor"
  />
  <path
    class="board"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.84328 3C5.73871 3 4.84328 3.89543 4.84328 5V19C4.84328 20.1046 5.73871 21 6.84328 21H18.8433C19.9478 21 20.8433 20.1046 20.8433 19V5C20.8433 3.89543 19.9478 3 18.8433 3V4C18.8433 5.10457 17.9478 6 16.8433 6H8.84328C7.73871 6 6.84328 5.10457 6.84328 4V3ZM17.5504 10.7071C17.9409 10.3166 17.9409 9.68342 17.5504 9.29289C17.1599 8.90237 16.5267 8.90237 16.1362 9.29289L11.8433 13.5858L10.5504 12.2929C10.1599 11.9024 9.52669 11.9024 9.13617 12.2929C8.74565 12.6834 8.74565 13.3166 9.13617 13.7071L11.1362 15.7071C11.5267 16.0976 12.1599 16.0976 12.5504 15.7071L17.5504 10.7071Z"
    fill="currentColor"
  />
</template>
